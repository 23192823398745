import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import logoImage from "../../assets/img/logoWhite.png";
import WaitlistForm from "../WaitlistForm/WaitlistForm";
import Modal from "../common/Modal";

const Navbar: React.FC = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = () => {
    setIsSticky(window.scrollY > 0);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <nav
        className={`navbar ${isSticky ? "sticky" : ""} ${isMenuOpen ? "menu-open" : ""}`}
      >
        <div className={`logo ${isMenuOpen ? "menu-open" : ""}`}>
          <img src={logoImage} alt="Expenses graph" className="logoImage" />
          <span className="logo-text">Hirelyst</span>
        </div>

        <div className={`nav-actions ${isMenuOpen ? "menu-open" : ""}`}>
          <ul className={`nav-links ${isMenuOpen ? "menu-open" : ""}`}>
            <li>
              <a href="#about">About</a>
            </li>
            <li>
              <a href="#contact">Contact</a>
            </li>
            <li>
              <button
                onClick={openModal}
                className={`button ${isMenuOpen ? "menu-open" : ""}`}
              >
                Book a Demo
              </button>
            </li>
          </ul>
        </div>
        <button
          className={`hamburger ${isMenuOpen ? "menu-open" : ""}`}
          onClick={toggleMenu}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              className="line line-1"
              d="M3 6H21"
              stroke="#004838"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              className="line line-2"
              d="M3 12H21"
              stroke="#004838"
              strokeWidth="2"
              strokeLinecap="round"
            />
            <path
              className="line line-3"
              d="M3 18H21"
              stroke="#004838"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </nav>

      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <WaitlistForm />
      </Modal>
    </>
  );
};

export default Navbar;
