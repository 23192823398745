import React, { useState } from "react";
import styles from "./ElevateSection.module.scss";
import graphImage from "./assets/img/graph.png";
import logoImage from "../../assets/img/logoWhite.svg";
import Modal from "../common/Modal";
import WaitlistForm from "../WaitlistForm/WaitlistForm";

const ElevateSection: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className={styles.innerCard}>
        <div className={styles.contentContainer}>
          <div className={styles.subtitleContainer}>
            <img
              src={logoImage}
              alt="Hirelyst logo"
              className={styles.logoImage}
            />
            <p className={styles.subtitle}>
              The next-gen cybersecurity hiring platform
            </p>
          </div>

          <h1 className={styles.title}>
            Elevate your hiring
            <br />
            with <i className={styles.hirelystSpan}>hirelyśt!</i>
          </h1>
          <p className={styles.description}>
            Ready to transform your cybersecurity hiring? Get started, book a
            demo and see how our platform can help you find the best cyber
            security talent!
          </p>
          <button onClick={openModal} className={styles.demoButton}>
            Book a Demo <span className={styles.arrow}>→</span>
          </button>
        </div>
        <img
          src={graphImage}
          alt="Graph showing data"
          className={styles.graphImage}
        />
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <WaitlistForm />
      </Modal>
    </>
  );
};

export default ElevateSection;
