import React, { useState } from "react";
import ExpandableCard from "../common/ExpandableCard";
import styles from "./FeatureSection.module.scss";
import laptopImage from "./assets/img/laptopImage.png";
import Modal from "../common/Modal";
import WaitlistForm from "../WaitlistForm/WaitlistForm";

const expandableData = [
  {
    title: "CTF Challenges",
    content:
      "Assess your candidates' hands-on expertise through our interactive Capture The Flag (CTF) challenges. CTF is a gamified environment where cybersecurity professionals solve real-world security problems.",
  },
  {
    title: "Secure Code Reviews",
    content:
      "Assess your candidates' hands-on expertise through secure code reviews, ensuring candidates can find and fix vulnerabilities in real-world scenarios.",
  },
  {
    title: "Cybersecurity Questions Library",
    content:
      "Access a comprehensive library of questions tailored to assess cybersecurity knowledge in various domains.",
  },
  {
    title: "Candidate Assessment Reports",
    content:
      "Get detailed assessment reports, helping you make informed hiring decisions with real-time candidate performance insights.",
  },
];

const FeatureSection: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const [openIndex, setOpenIndex] = useState<number | null>(0);

  const handleCardClick = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <div className={styles.outerCard}>
      <div className={styles.contentContainer}>
        <div>
          <h1 className={styles.title}>
            Why <i className={styles.hirelystSpan}>hirelyśt!</i> stands out
          </h1>
          <p className={styles.description}>
            Stand out by delivering tailored cybersecurity hiring solutions with
            real-world assessments, all on one streamlined platform.
          </p>
        </div>
        <div className={styles.buttonContainer}>
          <button onClick={openModal} className={styles.demoButton}>
            Book a Demo <span className={styles.arrow}>→</span>
          </button>
        </div>
      </div>
      <div className={styles.innerCard}>
        <img
          src={laptopImage}
          alt="Laptop showing graph"
          className={styles.laptopImage}
        />
        <div className={styles.expandableDiv}>
          {expandableData.map((card, index) => (
            <ExpandableCard
              key={card.title}
              title={card.title}
              isOpen={openIndex === index}
              onClick={() => handleCardClick(index)}
            >
              {card.content}
            </ExpandableCard>
          ))}
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <WaitlistForm />
      </Modal>
    </div>
  );
};

export default FeatureSection;
