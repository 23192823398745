import React, { useState } from "react";
import styles from "./Benefits.module.scss";
import checkIcon from "./assets/img/checkIcon.svg";
import { MdArrowBack, MdArrowForward } from "react-icons/md";
import graphImage from "./assets/img/graph.png";
import graphImage2 from "./assets/img/graph2.png";
import graphImage3 from "./assets/img/graph3.png";

const cardData = [
  {
    title: "Real-World Assessments",
    description:
      "We use predefined CTFs and practical challenges that reflect real-world cybersecurity scenarios, allowing companies to assess a candidate’s hands-on skills in areas like web and network security.",
    features: [
      "Predefined CTF Challenges",
      "Secure Code Review",
      "Questions Library",
    ],
    image: graphImage,
  },
  {
    title: "Simplified Candidate Management",
    description:
      "The platform provides an easy-to-use dashboard for inviting candidates, monitoring their progress, and reviewing performance, all in one place, streamlining the hiring workflow.",
    features: [
      "Automated Reporting",
      "Real-Time Analytics",
      "Intuitive Dashboard",
    ],
    image: graphImage3,
  },
];

const Benefits: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % cardData.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? cardData.length - 1 : prevIndex - 1
    );
  };

  const currentCard = cardData[currentIndex];

  return (
    <div className={styles.outerCard}>
      <div className={styles.parentContainer}>
        <div className={styles.benefitHeader}>
          <h1 className={styles.title}>
            Unlock the <i className={styles.benefitsSpan}>Benefits</i> of
            cybersecurity hiring
          </h1>
          <p className={styles.description}>
            Streamlining the entire cybersecurity hiring process, allowing you
            to evaluate candidates efficiently while ensuring they meet the
            highest standards.
          </p>
        </div>
        <div className={styles.arrowContainer}>
          <button onClick={handlePrevious} className={styles.arrowButton}>
            <MdArrowBack className={styles.arrowIcon} />
          </button>
          <button onClick={handleNext} className={styles.arrowButton}>
            <MdArrowForward className={styles.arrowIcon} />
          </button>
        </div>
      </div>
      <div className={styles.innerCard}>
        <div className={styles.contentContainer}>
          <h1 className={styles.title}>{currentCard.title}</h1>
          <p className={styles.cardDescription}>{currentCard.description}</p>
          <ul className={styles.featureList}>
            {currentCard.features.map((feature, index) => (
              <li key={index} className={styles.featureItem}>
                <img src={checkIcon} alt="Graph" className={styles.checkIcon} />
                {feature}
              </li>
            ))}
          </ul>
        </div>
        <img
          src={currentCard.image}
          alt="Graph"
          className={styles.graphImage}
        />
      </div>
    </div>
  );
};

export default Benefits;
