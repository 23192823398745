import React, { useRef, useEffect } from "react";
import "./ExpandableCard.scss";

type ExpandableCardProps = {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClick: () => void;
};

const ExpandableCard: React.FC<ExpandableCardProps> = ({
  title,
  children,
  isOpen,
  onClick,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isOpen
        ? `${contentRef.current.scrollHeight}px`
        : "0";
    }
  }, [isOpen]);

  return (
    <div className={`card ${isOpen ? "open" : ""}`}>
      <div className="buffer">
        <div className="card-header" onClick={onClick}>
          {title}
          <svg
            className="toggle-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polyline points="4 8 12 16 20 8" />
          </svg>
        </div>
        <div className="card-body" ref={contentRef}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ExpandableCard;
