import React from "react";
import styles from "./Footer.module.scss";
import logoImage from "../../assets/img/logoWhite.png";
import {
  FacebookIcon,
  InstagramIcon,
  LinkedInIcon,
  XIcon,
} from "../common/Icons";

const footerData = [
  {
    title: "Product",
    links: [
      { name: "Features", url: "/features" },
      { name: "Integrations", url: "/integrations" },
      { name: "Pricing", url: "/pricing" },
      { name: "Contact", url: "/contact" },
    ],
  },
  {
    title: "Company",
    links: [
      { name: "About", url: "/about" },
      { name: "Blogs", url: "/blogs" },
      { name: "Help Center", url: "/help-center" },
    ],
  },
  {
    title: "Other",
    links: [
      { name: "Privacy", url: "/privacy" },
      { name: "Terms of Service", url: "/terms" },
      { name: "Data Security & Compliance", url: "/data-security" },
    ],
  },
];

const Footer: React.FC = () => {
  return (
    <div className={styles.outerCard}>
      <div className={styles.footerContainer}>
        <div className={styles.brandSection}>
          <div className={styles.brandTitle}>
            <div className={styles.logoContainer}>
              <img
                src={logoImage}
                alt="Hirelyst logo"
                className={styles.logoImage}
              />
              <h2>Hirelyst</h2>
            </div>
            <p className={styles.description}>
              Start your next cybersecurity hiring journey with Hirelyst.
            </p>
          </div>
          <div className={styles.icons}>
            <FacebookIcon />
            <XIcon />
            <InstagramIcon />
            <LinkedInIcon />
          </div>
        </div>
        <div className={styles.linksSection}>
          {footerData.map((section) => (
            <div className={styles.linksBlock} key={section.title}>
              <h3 className="font-semibold mb-2">{section.title}</h3>
              <ul>
                {section.links.map((link) => (
                  <li key={link.url}>
                    <a href={link.url}>{link.name}</a>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
