import React, { useState, useEffect } from "react";
import "./WaitlistForm.scss";
import userIcon from "./assets/img/userIcon.png";
import { ENV } from "../../env";
import toast from "react-hot-toast";

const WaitlistForm: React.FC = () => {
  const [email, setEmail] = useState("");
  const [userCount, setUserCount] = useState<number | null>(null);
  const [loading, setLoading] = useState(true); // For fetching user count
  const [submitting, setSubmitting] = useState(false); // New state for submitting email

  const fetchUserCount = async () => {
    try {
      const response = await fetch(ENV.SHEET_API_URL, {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error("Failed to fetch user count");
      }
      const data = await response.json();
      setUserCount(data.rowCount - 1); // Set the row count in state
    } catch (error) {
      console.error("Error fetching user count:", error);
    } finally {
      setLoading(false); // Turn off the loading indicator after the response
    }
  };

  useEffect(() => {
    fetchUserCount();
  }, []);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (!email) {
      toast.error("Please enter an email");
      return;
    }

    setSubmitting(true); // Set submitting to true

    try {
      const response = await fetch(ENV.SHEET_API_URL, {
        method: "POST",
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error("Failed to submit email");
      }

      const data = await response.json();

      // Handle success or info status
      if (data.status === "success") {
        toast.success(`Email added: ${data.email}`, {
          style: {
            overflow: "scroll",
          },
        });
      } else if (data.status === "info") {
        toast.success(`Email already exists: ${data.email}`, {
          style: {
            overflow: "scroll",
          },
        });
      }

      // Optionally clear the email field or fetch user count again
      setEmail("");
      fetchUserCount(); // Fetch updated count after adding or checking email
    } catch (error) {
      console.error("Error submitting email:", error);
      toast.error("There was an error submitting the email. Please try again.");
    } finally {
      setSubmitting(false); // Set submitting back to false after the API response
    }
  };

  return (
    <div className="waitlist-container">
      <div className="header">
        <p className="launching-date">LAUNCHING Dec 5TH, 2024</p>
        <h1>Ready to hire and assess cybersecurity experts?</h1>
        <p>
          Build your cybersecurity dream team faster than ever. Join Hirelyst’s
          waitlist for exclusive early access!
        </p>
      </div>
      <form onSubmit={handleSubmit} className="waitlist-form">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="name@domain.com"
          required
        />
        <button type="submit" disabled={submitting}>
          {submitting ? "Submitting..." : "Join now"}
        </button>
      </form>
      <div className="footer">
        <img src={userIcon} style={{ width: "5rem" }} alt="User Icons" />
        <span>
          {submitting
            ? "Submitting your email..." // Show this when email is being submitted
            : loading
              ? "Loading users..."
              : `${userCount} users have already joined`}
        </span>
      </div>
    </div>
  );
};

export default WaitlistForm;
