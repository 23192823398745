import React from "react";
import Navbar from "./components/Navbar/Navbar";
import "./App.scss";
import HeroSection from "./components/HeroSection/HeroSection";
import FeatureSection from "./components/FeatureSection/FeatureSection";
import Footer from "./components/Footer/Footer";
import Benefits from "./components/Benefits/Benefits";
import ElevateSection from "./components/ElevateSection/ElevateSection";
import backgroundImage from "./assets/img/backgroundImage.png";
import toast, { Toaster } from "react-hot-toast";
function App() {
  return (
    <div className="App">
      <Toaster />
      <Navbar />
      <HeroSection />
      <img
        src={backgroundImage}
        alt="Expenses graph"
        className="backgroundImage"
      />
      <FeatureSection />
      <Benefits />
      <ElevateSection />
      <Footer />
    </div>
  );
}

export default App;
